import { BaseModule } from '../core/BaseModule'
import type { Post, Comment } from '../types/post'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class PostsModule extends BaseModule {
  /**
   * List posts by space ID
   */
  async listBySpace(spaceId: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Post>>> {
    return this.get(`/posts/space/${spaceId}`, params)
  }

  /**
   * List posts by group and space slugs
   */
  async listBySlug(groupSlug: string, spaceSlug: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Post>>> {
    return this.get(`/posts/by-slug/${groupSlug}/${spaceSlug}`, params)
  }

  /**
   * Get a post by ID
   */
  async getById(id: string): Promise<SDKResponse<Post>> {
    return this.get(`/posts/${id}`)
  }

  /**
   * Get a post by slugs
   */
  async getBySlug(groupSlug: string, spaceSlug: string, postSlug: string): Promise<SDKResponse<Post>> {
    return this.get(`/posts/by-slug/${groupSlug}/${spaceSlug}/${postSlug}`)
  }

  /**
   * Create a new post
   */
  async createPost(data: {
    slug: string
    spaceId: string
    type: string
    content: string
  }): Promise<SDKResponse<Post>> {
    return this.post('/posts', data)
  }

  /**
   * Update a post
   */
  async updatePost(id: string, data: {
    type?: string
    content?: string
  }): Promise<SDKResponse<Post>> {
    return this.put(`/posts/${id}`, data)
  }

  /**
   * Delete a post
   */
  async deletePost(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/posts/${id}`)
  }

  /**
   * Add a reaction to a post
   */
  async addReaction(id: string, reaction: string): Promise<SDKResponse<Post>> {
    return this.post(`/posts/${id}/reactions`, { reaction })
  }

  /**
   * Remove a reaction from a post
   */
  async removeReaction(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/posts/${id}/reactions`)
  }

  /**
   * Add a comment to a post
   */
  async addComment(id: string, content: string): Promise<SDKResponse<Comment>> {
    return this.post(`/posts/${id}/comments`, { content })
  }

  /**
   * Get post comments
   */
  async getComments(id: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Comment>>> {
    return this.get(`/posts/${id}/comments`, params)
  }
}