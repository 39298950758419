import { BaseModule } from '../core/BaseModule'
import type { 
  Member, 
  MemberProfile,
  RegisterMemberParams, 
  LoginMemberParams,
  UpdateMemberParams 
} from '../types/member'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class MembersModule extends BaseModule {
  /**
   * Register a new member
   */
  async register(data: RegisterMemberParams): Promise<SDKResponse<Member>> {
    return this.post('/members/register', data)
  }

  /**
   * Login member
   */
  async login(data: LoginMemberParams): Promise<SDKResponse<{ token: string }>> {
    return this.post('/members/login', data)
  }

  /**
   * Update account
   */
  async updateAccount(data: UpdateMemberParams): Promise<SDKResponse<Member>> {
    return this.put('/members/account', data)
  }

  /**
   * Delete account
   */
  async deleteAccount(): Promise<SDKResponse<void>> {
    return this.delete('/members/account')
  }

  /**
   * List community members
   */
  async listCommunityMembers(
    communityId: string,
    params?: PaginationParams
  ): Promise<SDKResponse<PaginatedResponse<Member>>> {
    return this.get(`/members/community/${communityId}`, params)
  }

  /**
   * Get member profile
   */
  async getProfile(memberId: string): Promise<SDKResponse<MemberProfile>> {
    return this.get(`/members/${memberId}`)
  }

  /**
   * Follow member
   */
  async follow(profileId: string): Promise<SDKResponse<void>> {
    return this.post(`/members/profile/${profileId}/follow`)
  }

  /**
   * Unfollow member
   */
  async unfollow(profileId: string): Promise<SDKResponse<void>> {
    return this.delete(`/members/profile/${profileId}/follow`)
  }

  /**
   * Get followers
   */
  async getFollowers(
    profileId: string,
    params?: PaginationParams
  ): Promise<SDKResponse<PaginatedResponse<MemberProfile>>> {
    return this.get(`/members/profile/${profileId}/followers`, params)
  }

  /**
   * Get following
   */
  async getFollowing(
    profileId: string,
    params?: PaginationParams
  ): Promise<SDKResponse<PaginatedResponse<MemberProfile>>> {
    return this.get(`/members/profile/${profileId}/following`, params)
  }
}