import { Avatar, AvatarImage } from "@/components/ui/avatar"

interface User {
  id: string
  name: string
  avatar: string
}

interface LikedByProps {
  users: User[]
}

export function LikedBy({ users }: LikedByProps) {
  if (users.length === 0) return null

  return (
    <div className="flex items-center gap-2">
      <div className="flex -space-x-2">
        {users.slice(0, 3).map((user) => (
          <Avatar key={user.id} className="h-6 w-6 border-2 border-background">
            <AvatarImage src={user.avatar} alt={user.name} />
          </Avatar>
        ))}
      </div>
      <span className="text-sm text-muted-foreground">
        Liked by {users[0].name}
        {users.length > 1 && ` and ${users.length - 1} others`}
      </span>
    </div>
  )
}