import { BaseModule } from '../core/BaseModule'
import type { Course } from '../types/course'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class CoursesModule extends BaseModule {
  /**
   * List courses by space ID
   */
  async listBySpace(spaceId: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Course>>> {
    return this.get(`/courses/space/${spaceId}`, params)
  }

  /**
   * List courses by group and space slugs
   */
  async listBySlug(groupSlug: string, spaceSlug: string): Promise<SDKResponse<PaginatedResponse<Course>>> {
    return this.get(`/courses/by-slug/${groupSlug}/${spaceSlug}`)
  }

  /**
   * Get a course by ID
   */
  async getById(id: string): Promise<SDKResponse<Course>> {
    return this.get(`/courses/${id}`)
  }

  /**
   * Get a course by slugs
   */
  async getBySlug(groupSlug: string, spaceSlug: string, courseSlug: string): Promise<SDKResponse<Course>> {
    return this.get(`/courses/by-slug/${groupSlug}/${spaceSlug}/${courseSlug}`)
  }

  /**
   * Create a new course
   */
  async createCourse(data: {
    slug: string
    spaceId: string
    title: string
    description: string
    bannerUrl?: string
    type: string
    level: string
    isFeatured: boolean
    modules: Array<{
      title: string
      lectures: Array<{
        title: string
        content: string
        videoUrl?: string
      }>
    }>
  }): Promise<SDKResponse<Course>> {
    return this.post('/courses', data)
  }

  /**
   * Update a course
   */
  async updateCourse(id: string, data: {
    title?: string
    description?: string
    level?: string
    isFeatured?: boolean
    modules?: Array<{
      title: string
      lectures: Array<{
        title: string
        content: string
        videoUrl?: string
      }>
    }>
  }): Promise<SDKResponse<Course>> {
    return this.put(`/courses/${id}`, data)
  }

  /**
   * Delete a course
   */
  async deleteCourse(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/courses/${id}`)
  }

  /**
   * Enroll student in course
   */
  async enrollCourse(id: string): Promise<SDKResponse<void>> {
    return this.post(`/courses/${id}/enroll`)
  }

  /**
   * Get student progress
   */
  async getProgress(id: string): Promise<SDKResponse<{
    completed: number
    total: number
    lastAccessed: string
  }>> {
    return this.get(`/courses/${id}/progress`)
  }

  /**
   * Update lecture progress
   */
  async updateProgress(lectureId: string, data: {
    completed: boolean
    progressPercentage: number
  }): Promise<SDKResponse<void>> {
    return this.post(`/courses/lectures/${lectureId}/progress`, data)
  }
}