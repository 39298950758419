import { Link } from 'react-router-dom'
import { 
  Plus, 
  ChevronRight,
  MessageSquare,
  Calendar,
  MessagesSquare,
  Users,
  GraduationCap,
  Home, 
  Circle
} from 'lucide-react'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { cn } from '@/lib/utils'
import { MenuGroup, MenuSpace, moduleHasAction } from '@/sdk/types/space'

interface SidebarSpacesProps {
  groups: MenuGroup[]
  openGroups: string[]
  onToggleGroup: (groupId: string) => void
  onAction: (space: MenuSpace) => void
  currentGroup?: string
  currentSpace?: string
}

const IconMap = {
  Circle,
  MessageSquare,
  Calendar,
  MessagesSquare,
  Users,
  GraduationCap,
}

export function SidebarSpaces({
  groups,
  openGroups,
  onToggleGroup,
  onAction,
  currentGroup,
  currentSpace
}: SidebarSpacesProps) {
  const isActiveRoute = (groupSlug: string, spaceSlug: string) => {
    return currentGroup === groupSlug && currentSpace === spaceSlug
  }

  return (
    <ScrollArea className="flex-1 px-2">
      <div className="space-y-2 py-2">
        {/* Dashboard with Create Group Button */}
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            className={cn(
              'w-full justify-start gap-2',
              location.pathname === '/dashboard' &&
                'bg-[hsl(var(--sidebarHover))]',
              'hover:bg-[hsl(var(--sidebarHover))]'
            )}
            asChild
          >
            <Link to="/dashboard">
              <Home className="h-4 w-4" />
              Dashboard
            </Link>
          </Button>
        </div>

        {/* App Groups */}
        <div className="space-y-2">
          {groups.map((group) => (
            <Collapsible
              key={group.id}
              open={openGroups.includes(group.id)}
              onOpenChange={() => onToggleGroup(group.id)}
            >
              <CollapsibleTrigger asChild>
                <Button variant="ghost" className="w-full justify-between">
                  <span>{group.name}</span>
                  <ChevronRight
                    className={cn(
                      'h-4 w-4 transition-transform',
                      openGroups.includes(group.id) && 'rotate-90'
                    )}
                  />
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-1 px-2 py-2">
                {group.spaces.map((space) => {
                  
                  const Icon = IconMap[space.icon as keyof typeof IconMap]
                  
                  return (
                    <div key={space.id} className="flex items-center gap-2">
                      <Button
                        variant="ghost"
                        className={cn(
                          'w-full justify-start gap-2 h-8',
                          isActiveRoute(group.slug, space.slug) &&
                            'bg-[hsl(var(--sidebarHover))]',
                          'hover:bg-[hsl(var(--sidebarHover))]'
                        )}
                        asChild
                      >
                        <Link to={`/g/${group.slug}/${space.slug}`}>
                          <Icon className="h-4 w-4" />
                          {space.name}
                        </Link>
                      </Button>
                      {moduleHasAction(space.type) && (
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 hover:bg-[hsl(var(--sidebarHover))]"
                          onClick={() => onAction(space)}
                        >
                          <Plus className="h-4 w-4" />
                        </Button>
                      )}
                    </div>
                  )
                })}
              </CollapsibleContent>
            </Collapsible>
          ))}
        </div>
      </div>
    </ScrollArea>
  )
}