import { BaseModule } from '../core/BaseModule'
import type { Channel, ChannelThread } from '../types/channel'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class ChannelsModule extends BaseModule {
  /**
   * List channels by space ID
   */
  async listBySpace(spaceId: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Channel>>> {
    return this.get(`/channels/space/${spaceId}`, params)
  }

  /**
   * List channels by group and space slugs
   */
  async listBySlug(groupSlug: string, spaceSlug: string): Promise<SDKResponse<PaginatedResponse<Channel>>> {
    return this.get(`/channels/by-slug/${groupSlug}/${spaceSlug}`)
  }

  /**
   * Get a channel by ID
   */
  async getById(id: string): Promise<SDKResponse<Channel>> {
    return this.get(`/channels/${id}`)
  }

  /**
   * Get a channel by slugs
   */
  async getBySlug(groupSlug: string, spaceSlug: string, channelSlug: string): Promise<SDKResponse<Channel>> {
    return this.get(`/channels/by-slug/${groupSlug}/${spaceSlug}/${channelSlug}`)
  }

  /**
   * Create a new channel
   */
  async createChannel(data: {
    spaceId: string
    name: string
    type: string
    visibility: string
  }): Promise<SDKResponse<Channel>> {
    return this.post('/channels', data)
  }

  /**
   * Update a channel
   */
  async updateChannel(id: string, data: {
    name?: string
    type?: string
    visibility?: string
  }): Promise<SDKResponse<Channel>> {
    return this.put(`/channels/${id}`, data)
  }

  /**
   * Delete a channel
   */
  async deleteChannel(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/channels/${id}`)
  }

  /**
   * List threads in a channel
   */
  async listThreads(channelId: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<ChannelThread>>> {
    return this.get(`/channels/${channelId}/threads`, params)
  }

  /**
   * Create a thread
   */
  async createThread(channelId: string, content: string): Promise<SDKResponse<ChannelThread>> {
    return this.post(`/channels/${channelId}/threads`, { content })
  }

  /**
   * Add reaction to thread
   */
  async addThreadReaction(threadId: string, reaction: string): Promise<SDKResponse<ChannelThread>> {
    return this.post(`/channels/threads/${threadId}/reactions`, { reaction })
  }

  /**
   * Add reply to thread
   */
  async addThreadReply(threadId: string, content: string): Promise<SDKResponse<ChannelThread>> {
    return this.post(`/channels/threads/${threadId}/replies`, { content })
  }

  /**
   * Pin message
   */
  async pinMessage(channelId: string, messageId: string): Promise<SDKResponse<void>> {
    return this.post(`/channels/${channelId}/pins/${messageId}`)
  }

  /**
   * Unpin message
   */
  async unpinMessage(channelId: string, messageId: string): Promise<SDKResponse<void>> {
    return this.delete(`/channels/${channelId}/pins/${messageId}`)
  }
}