import { BaseModule } from '../core/BaseModule'
import type { Event, ChatMessage, Question } from '../types/event'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class EventsModule extends BaseModule {
  /**
   * List events by space ID
   */
  async listBySpace(spaceId: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Event>>> {
    return this.get(`/events/space/${spaceId}`, params)
  }

  /**
   * List events by group and space slugs
   */
  async listBySlug(groupSlug: string, spaceSlug: string): Promise<SDKResponse<PaginatedResponse<Event>>> {
    return this.get(`/events/by-slug/${groupSlug}/${spaceSlug}`)
  }

  /**
   * Get an event by ID
   */
  async getById(id: string): Promise<SDKResponse<Event>> {
    return this.get(`/events/${id}`)
  }

  /**
   * Get an event by slugs
   */
  async getBySlug(groupSlug: string, spaceSlug: string, eventSlug: string): Promise<SDKResponse<Event>> {
    return this.get(`/events/by-slug/${groupSlug}/${spaceSlug}/${eventSlug}`)
  }

  /**
   * Create a new event
   */
  async createEvent(data: {
    slug: string
    spaceId: string
    title: string
    description: string
    bannerUrl?: string
    type: string
    startTime: string
    endTime: string
    location?: string
  }): Promise<SDKResponse<Event>> {
    return this.post('/events', data)
  }

  /**
   * Update an event
   */
  async updateEvent(id: string, data: {
    title?: string
    description?: string
    startTime?: string
    endTime?: string
    location?: string
  }): Promise<SDKResponse<Event>> {
    return this.put(`/events/${id}`, data)
  }

  /**
   * Delete an event
   */
  async deleteEvent(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/events/${id}`)
  }

  /**
   * Update participation status
   */
  async rsvp(id: string, status: 'going' | 'maybe' | 'not-going'): Promise<SDKResponse<Event>> {
    return this.post(`/events/${id}/participation`, { status })
  }

  /**
   * Add comment
   */
  async addComment(id: string, content: string): Promise<SDKResponse<Comment>> {
    return this.post(`/events/${id}/comments`, { content })
  }

  /**
   * List comments
   */
  async listComments(id: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Comment>>> {
    return this.get(`/events/${id}/comments`, params)
  }

  /**
   * Add question
   */
  async addQuestion(id: string, question: string): Promise<SDKResponse<Question>> {
    return this.post(`/events/${id}/questions`, { question })
  }

  /**
   * List questions
   */
  async listQuestions(id: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Question>>> {
    return this.get(`/events/${id}/questions`, params)
  }

  /**
   * Add chat message
   */
  async addChatMessage(id: string, content: string): Promise<SDKResponse<ChatMessage>> {
    return this.post(`/events/${id}/chat`, { content })
  }

  /**
   * List chat messages
   */
  async listChatMessages(id: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<ChatMessage>>> {
    return this.get(`/events/${id}/chat`, params)
  }
}