import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Separator } from '@/components/ui/separator'
import { SidebarHeader } from './SidebarHeader'
import { SidebarSpaces } from './SidebarSpaces'
import { SidebarFooter } from './SidebarFooter'
import { CreatePostDialog } from '@/components/posts/CreatePostDialog'
import { CreateEventDialog } from '@/components/events/CreateEventDialog'
import { CreateGroupDialog } from '@/components/groups/CreateGroupDialog'
import sdk from '@/sdk' // Adjust the import path as necessary
import { MenuSpace } from '@/sdk/types/space'

export function Sidebar() {
  const location = useLocation()
  const [showCreatePost, setShowCreatePost] = useState(false)
  const [showCreateEvent, setShowCreateEvent] = useState(false)
  const [showCreateGroup, setShowCreateGroup] = useState(false)
  const [menuGroups, setMenuGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [openGroups, setOpenGroups] = useState<string[]>([])

  useEffect(() => {
    const loadMenuGroups = async () => {
      try {
        const response = await sdk.spaces.getMenu()
        const groups = response.data.data ?? []
        setMenuGroups(groups)
        setOpenGroups(groups.map((group: { id: string }) => group.id))
      } catch (error) {
        console.error('Failed to load menu groups:', error)
      } finally {
        setLoading(false)
      }
    }

    loadMenuGroups()
  }, [])

  const toggleGroup = (groupId: string) => {
    setOpenGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    )
  }

  const handleAction = (space: MenuSpace) => {
    switch (space.type) {
      case 'posts':
        setShowCreatePost(true)
        break
      case 'events':
        setShowCreateEvent(true)
        break
      case 'courses':
        // Handle course creation
        break
      case 'groups':
        setShowCreateGroup(true)
        break
    }
  }

  if (loading) {
    return (
      <div className="w-64 h-screen bg-[hsl(var(--sidebarBackground))] border-r flex items-center justify-center">
        <div className="text-muted-foreground">Loading...</div>
      </div>
    )
  }

  return (
    <div className="w-64 h-screen bg-[hsl(var(--sidebarBackground))] border-r flex flex-col">
      <SidebarHeader />
      <Separator />
      <SidebarSpaces
        groups={menuGroups}
        openGroups={openGroups}
        onToggleGroup={toggleGroup}
        onAction={handleAction}
      />
      <SidebarFooter />

      {/* Dialogs */}
      <CreatePostDialog
        open={showCreatePost}
        onOpenChange={setShowCreatePost}
      />
      <CreateEventDialog
        open={showCreateEvent}
        onOpenChange={setShowCreateEvent}
      />
      <CreateGroupDialog
        open={showCreateGroup}
        onOpenChange={setShowCreateGroup}
      />
    </div>
  )
}