import { BaseModule } from '../core/BaseModule'
import type { Group, CreateGroupParams, UpdateGroupParams } from '../types/group'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class GroupsModule extends BaseModule {
  /**
   * List all groups
   */
  async list(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Group>>> {
    return this.get('/groups', params)
  }

  /**
   * Get a group by ID
   */
  async getGroup(id: string): Promise<SDKResponse<Group>> {
    return this.get(`/groups/${id}`)
  }

  /**
   * Create a new group
   */
  async createGroup(data: CreateGroupParams): Promise<SDKResponse<Group>> {
    return this.post('/groups', data)
  }

  /**
   * Update a group
   */
  async updateGroup(id: string, data: UpdateGroupParams): Promise<SDKResponse<Group>> {
    return this.put(`/groups/${id}`, data)
  }

  /**
   * Delete a group
   */
  async deleteGroup(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/groups/${id}`)
  }
}