import type { SDKConfig, SDKResponse } from '../types'

export class BaseModule {
  constructor(protected config: SDKConfig) {}

  protected async get<T>(path: string, params?: Record<string, any>): Promise<SDKResponse<T>> {
    return this.request<T>('GET', path, { params })
  }

  protected async post<T>(path: string, data?: unknown): Promise<SDKResponse<T>> {
    return this.request<T>('POST', path, { data })
  }

  protected async put<T>(path: string, data?: unknown): Promise<SDKResponse<T>> {
    return this.request<T>('PUT', path, { data })
  }

  protected async delete<T>(path: string): Promise<SDKResponse<T>> {
    return this.request<T>('DELETE', path)
  }

  private async request<T>(
    method: string,
    path: string,
    options: {
      params?: Record<string, any>
      data?: unknown
    } = {}
  ): Promise<SDKResponse<T>> {
    const url = new URL(`${this.config.baseUrl}${path}`)
    
    if (options.params) {
      Object.entries(options.params).forEach(([key, value]) => {
        if (value !== undefined) {
          url.searchParams.append(key, String(value))
        }
      })
    }

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }

    if (this.config.apiKey) {
      headers.Authorization = `Bearer ${this.config.apiKey}`
    }

    try {
      const response = await fetch(url.toString(), {
        method,
        headers,
        body: options.data ? JSON.stringify(options.data) : undefined,
      })

      const data = await response.json()

      return {
        data: response.ok ? data : null,
        status: response.status,
        error: !response.ok ? data.message || 'Request failed' : undefined
      }
    } catch (error) {
      return {
        data: null,
        status: 500,
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      }
    }
  }
}