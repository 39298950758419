import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Banner } from '@/components/navigation/Banner'
import { PostCard } from '@/components/posts/PostCard'
import { PostSkeletonList } from '@/components/posts/PostSkeleton'
import { PageBreadcrumb } from '@/components/navigation/PageBreadcrumb'
import sdk from '@/sdk'
import type { Post } from '@/sdk/types/post'
import { Space } from '@/sdk/types/space'

export function Posts({ space }: { space: Space}) {
  const { group_slug, space_slug } = useParams()
  const [posts, setPosts] = useState<Post[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const loadPosts = async () => {
      if (!group_slug || !space_slug) return

      try {
        const response = await sdk.posts.listBySlug(group_slug, space_slug)
        if (response.data) {
          setPosts(response.data.items || [])
        }
      } catch (error) {
        console.error('Failed to load posts:', error)
        setError('Failed to load posts. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    loadPosts()
  }, [space_slug])

  const handleReaction = async (postId: string, emoji: string) => {
    try {
      const response = await sdk.posts.addReaction(postId, emoji)
      if (response.data) {
        setPosts(posts.map(post => 
          post.id === postId ? response.data as Post : post
        ))
      }
    } catch (error) {
      console.error('Failed to add reaction:', error)
    }
  }

  const handleComment = async (postId: string, content: string) => {
    try {
      const response = await sdk.posts.addComment(postId, { content })
      if (response.data) {
        setPosts(posts.map(post => 
          post.id === postId ? response.data as Post : post
        ))
      }
    } catch (error) {
      console.error('Failed to add comment:', error)
    }
  }

  if (error) return <div className="text-red-500">{error}</div>

  return (
    <div className="space-y-8">
      <PageBreadcrumb />
      
      <Banner space={space} />

      {loading ? (
        <PostSkeletonList />
      ) : (
        <div className="space-y-6">
          {posts.map((post) => (
            <PostCard
              key={post.id}
              post={post}
              onReaction={handleReaction}
              onComment={handleComment}
            />
          ))}
        </div>
      )}
    </div>
  )
}
