import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Search } from 'lucide-react'
import { Banner } from '@/components/navigation/Banner'
import { Input } from '@/components/ui/input'
import { CourseList } from '@/components/courses/CourseList'
import { FeaturedCourses } from '@/components/courses/FeaturedCourses'
import { CoursesSkeletonList } from '@/components/courses/CoursesSkeleton'
import { PageBreadcrumb } from '@/components/navigation/PageBreadcrumb'
import sdk from '@/sdk'
import type { Course } from '@/sdk/types/course'
import { Space } from '@/sdk/types/space'

export function Courses({ space }: { space: Space }) {
  const { group_slug, space_slug } = useParams()
  const [courses, setCourses] = useState<Course[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const loadCourses = async () => {
      if (!group_slug || !space_slug) return
      try {
        const response = await sdk.courses.listBySlug(group_slug, space_slug)
        if (response.data) {
          setCourses(response.data.items)
        }
      } catch (err) {
        console.error('Failed to load courses:', err)
        setError('Failed to load courses. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    loadCourses()
  }, [])

  if (loading) {
    return <CoursesSkeletonList />
  }

  if (error) return <div className="text-red-500">{error}</div>
  if (!group_slug || !space_slug) return null

  const validCourses = courses ?? []; // Garante que `validEvents` seja um array

  const featuredCourses = validCourses.filter(course => course.isFeatured)
  const regularCourses = validCourses.filter(course => !course.isFeatured)

  const filteredCourses = regularCourses.filter(course =>
    course.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    course.description.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <div className="space-y-8">
      <PageBreadcrumb />

      <Banner space={space} />

      {/* Search */}
      <div className="relative">
        <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search courses..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="pl-10"
        />
      </div>

      {/* Featured Courses */}
      {featuredCourses.length > 0 && (
        <FeaturedCourses
          courses={featuredCourses}
          groupSlug={group_slug}
          spaceSlug={space_slug}
        />
      )}

      {/* Regular Courses */}
      <CourseList
        courses={filteredCourses}
        groupSlug={group_slug}
        spaceSlug={space_slug}
      />
    </div>
  )
}