import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"

export function Login() {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate('/dashboard')
  }

  return (
    <div className="max-w-md mx-auto">
      <div className="space-y-6">
        <div className="space-y-2 text-center">
          <h1 className="text-2xl font-bold">Login</h1>
          <p className="text-muted-foreground">Enter your credentials to continue</p>
        </div>
        
        <div className="space-y-4">
          <Button onClick={handleLogin} className="w-full">
            Sign In
          </Button>
        </div>
      </div>
    </div>
  )
}