import { formatDistanceToNow, isPast, parseISO } from 'date-fns'

export function getTimeUntilEvent(date: string, startTime: string): string {
  const eventDateTime = parseISO(`${date}T${convertTo24Hour(startTime)}`)
  
  if (isPast(eventDateTime)) {
    return 'Event has ended'
  }
  
  return `Starts ${formatDistanceToNow(eventDateTime, { addSuffix: true })}`
}

function convertTo24Hour(time12h: string): string {
  const [time, modifier] = time12h.split(' ')
  let [hours, minutes] = time.split(':')
  
  if (hours === '12') {
    hours = '00'
  }
  
  if (modifier === 'PM') {
    hours = String(parseInt(hours, 10) + 12)
  }
  
  return `${hours.padStart(2, '0')}:${minutes}:00`
}