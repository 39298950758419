import { BaseModule } from '../core/BaseModule'
import type { Community, CreateCommunityParams, UpdateCommunityParams } from '../types/community'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class CommunitiesModule extends BaseModule {
  /**
   * List public communities
   */
  async listPublic(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Community>>> {
    return this.get('/communities/public', params)
  }

  /**
   * Get a community by slug
   */
  async getBySlug(slug: string): Promise<SDKResponse<Community>> {
    return this.get(`/communities/${slug}`)
  }

  /**
   * Create a new community
   */
  async create(data: CreateCommunityParams): Promise<SDKResponse<Community>> {
    return this.post('/communities', data)
  }

  /**
   * Update a community
   */
  async update(slug: string, data: UpdateCommunityParams): Promise<SDKResponse<Community>> {
    return this.put(`/communities/${slug}`, data)
  }

  /**
   * Delete a community
   */
  async delete(slug: string): Promise<SDKResponse<void>> {
    return this.delete(`/communities/${slug}`)
  }
}