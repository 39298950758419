import { BaseModule } from '../core/BaseModule'
import type { Space, CreateSpaceParams, UpdateSpaceParams, MenuResponse } from '../types/space'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class SpacesModule extends BaseModule {
  /**
   * Get navigation menu structure
   */
  async getMenu(): Promise<SDKResponse<MenuResponse>> {
    return this.get('/spaces/menu')
  }

  /**
   * List spaces by group ID
   */
  async listByGroup(groupId: string, params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Space>>> {
    return this.get(`/spaces/group/${groupId}`, params)
  }

  /**
   * List spaces by group and space slugs
   */
  async listBySlug(groupSlug: string): Promise<SDKResponse<PaginatedResponse<Space>>> {
    return this.get(`/spaces/by-slug/${groupSlug}`)
  }

  /**
   * Get a space by ID
   */
  async getById(id: string): Promise<SDKResponse<Space>> {
    return this.get(`/spaces/${id}`)
  }

  /**
   * Get a space by slugs
   */
  async getBySlug(groupSlug: string, spaceSlug: string): Promise<SDKResponse<Space>> {
    return this.get(`/spaces/by-slug/${groupSlug}/${spaceSlug}`)
  }

  /**
   * Create a new space
   */
  async createSpace(data: CreateSpaceParams): Promise<SDKResponse<Space>> {
    return this.post('/spaces', data)
  }

  /**
   * Update a space
   */
  async updateSpace(id: string, data: UpdateSpaceParams): Promise<SDKResponse<Space>> {
    return this.put(`/spaces/${id}`, data)
  }

  /**
   * Delete a space
   */
  async deleteSpace(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/spaces/${id}`)
  }

  /**
   * Update space order
   */
  async updateOrder(id: string, order: number): Promise<SDKResponse<Space>> {
    return this.put(`/spaces/${id}/order`, { order })
  }
}