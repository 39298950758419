import { useState } from 'react'
import { MoreHorizontal, MessageCircle } from 'lucide-react'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { LikeButton } from '@/components/shared/LikeButton'
import { LikedBy } from '@/components/shared/LikedBy'
import { CommentSection } from '@/components/shared/CommentSection'
import type { Post } from '@/types/post'

interface PostCardProps {
  post: Post
  onReaction: (postId: string, emoji: string) => void
  onComment: (postId: string, content: string) => void
}

export function PostCard({ post, onReaction, onComment }: PostCardProps) {
  const [showComments, setShowComments] = useState(false)

  return (
    <div className="rounded-xl border bg-card overflow-hidden">
      {post.image && (
        <div className="h-48 overflow-hidden">
          <img
            src={post.image}
            alt="Post cover"
            className="w-full h-full object-cover"
          />
        </div>
      )}
      <div className="p-6 space-y-4">
        {/* Post Header */}
        <div className="flex items-start justify-between">
          <div className="flex items-start gap-3">
            <Avatar className="h-12 w-12">
              <AvatarImage src={post.user.avatar} alt={post.user.name} />
            </Avatar>
            <div>
              <h3 className="font-semibold">{post.user.name}</h3>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <span>{post.user.role}</span>
                <span>•</span>
                <span>{post.createdAt}</span>
              </div>
            </div>
          </div>
          <Button variant="ghost" size="icon">
            <MoreHorizontal className="h-5 w-5" />
          </Button>
        </div>

        {/* Post Content */}
        <div className="space-y-2">
          <h2 className="text-xl font-semibold">{post.title}</h2>
          <p className="text-muted-foreground">{post.content}</p>
        </div>

        {/* Likes */}
        <LikedBy users={post.likedBy} />

        {/* Actions */}
        <div className="flex items-center gap-4 pt-2 border-t">
          <LikeButton 
            initialReactions={post.reactions} 
            onReact={(emoji) => onReaction(post.id, emoji)}
          />
          <Button 
            variant="ghost" 
            size="sm" 
            className="gap-2"
            onClick={() => setShowComments(!showComments)}
          >
            <MessageCircle className="h-4 w-4" />
            <span>{post.comments.length} Comments</span>
          </Button>
        </div>

        {/* Comments */}
        {showComments && (
          <CommentSection
            comments={post.comments}
            onComment={(content) => onComment(post.id, content)}
          />
        )}
      </div>
    </div>
  )
}