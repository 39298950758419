export type SpaceType = 'posts' | 'events' | 'courses' | 'channels'  | 'members' 
export type SpaceVisibility = 'public' | 'private' | 'members'

export interface Space {
  id: string
  groupId: string
  slug: string
  name: string
  title: string 
  description?: string
  iconUrl?: string
  type: SpaceType
  visibility: SpaceVisibility
  order: number
  createdAt: string
  updatedAt: string
}

export interface CreateSpaceParams {
  groupId: string
  slug: string
  name: string
  description?: string
  iconUrl?: string
  type: SpaceType
  visibility?: SpaceVisibility
  order?: number
}

export interface UpdateSpaceParams extends Partial<Omit<CreateSpaceParams, 'groupId'>> {}

export interface MenuGroup {
  id: string
  name: string
  slug: string
  order: number
  spaces: MenuSpace[]
}

export interface MenuSpace {
  id: string
  name: string
  title: string
  description: string
  slug: string
  type?: SpaceType
  icon?: string
  order: number
}

export interface MenuResponse {
  groups: MenuGroup[]
}

// Helper to determine if a type has actions
export const moduleHasAction = (type: MenuSpace['type']): boolean => {
  return type ? ['posts', 'events', 'courses'].includes(type) : false
}