import { BaseModule } from '../core/BaseModule'
import type { Plan, CreatePlanParams, UpdatePlanParams } from '../types/pricing'
import type { PaginatedResponse, PaginationParams, SDKResponse } from '../types'

export class PricingModule extends BaseModule {
  /**
   * List all plans
   */
  async list(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Plan>>> {
    return this.get('/pricing/plans', params)
  }

  /**
   * Get a plan by ID
   */
  async get(id: string): Promise<SDKResponse<Plan>> {
    return this.get(`/pricing/plans/${id}`)
  }

  /**
   * Create a new plan
   */
  async create(data: CreatePlanParams): Promise<SDKResponse<Plan>> {
    return this.post('/pricing/plans', data)
  }

  /**
   * Update a plan
   */
  async update(id: string, data: UpdatePlanParams): Promise<SDKResponse<Plan>> {
    return this.put(`/pricing/plans/${id}`, data)
  }

  /**
   * Delete a plan
   */
  async delete(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/pricing/plans/${id}`)
  }
}