import { Space } from "@/sdk/types/space";

export function Banner({ space }: { space: Space }) {
  return (
    <div className="relative h-[240px] rounded-xl overflow-hidden">
      <img
        src="https://images.unsplash.com/photo-1707343843437-caacff5cfa74"
        alt="Community Banner"
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-r from-purple-500/80 to-purple-500/60 flex items-center p-8">
        <div className="text-white">
          <div className="inline-block px-4 py-1 rounded-full bg-white/20 text-sm font-medium mb-4">
            {space.name}
          </div>
          <h1 className="text-4xl font-bold mb-2">{space.title}</h1>
          <p className="text-lg text-white/90">{space.description}</p>
        </div>
      </div>
    </div>
  )
}