import { SDKConfig } from './types'
import { CommunitiesModule } from './modules/communities'
import { MembersModule } from './modules/members'
import { GroupsModule } from './modules/groups'
import { SpacesModule } from './modules/spaces'
import { PostsModule } from './modules/posts'
import { EventsModule } from './modules/events'
import { CoursesModule } from './modules/courses'
import { ChannelsModule } from './modules/channels'
import { PricingModule } from './modules/pricing'
import { env } from '@/config/env'

export class MemberfySDK {
  public communities: CommunitiesModule
  public members: MembersModule
  public groups: GroupsModule
  public spaces: SpacesModule
  public posts: PostsModule
  public events: EventsModule
  public courses: CoursesModule
  public channels: ChannelsModule
  public pricing: PricingModule

  constructor(config: SDKConfig = {}) {
    const finalConfig = {
      baseUrl: env.apiBaseUrl,
      ...config
    }

    this.communities = new CommunitiesModule(finalConfig)
    this.members = new MembersModule(finalConfig)
    this.groups = new GroupsModule(finalConfig)
    this.spaces = new SpacesModule(finalConfig)
    this.posts = new PostsModule(finalConfig)
    this.events = new EventsModule(finalConfig)
    this.courses = new CoursesModule(finalConfig)
    this.channels = new ChannelsModule(finalConfig)
    this.pricing = new PricingModule(finalConfig)
  }
}

// Export default instance
export default new MemberfySDK()

// Export types
export * from './types'
export * from './modules/communities'
export * from './modules/members'
export * from './modules/groups'
export * from './modules/spaces'
export * from './modules/posts'
export * from './modules/events'
export * from './modules/courses'
export * from './modules/channels'
export * from './modules/pricing'